<template>
  <el-dialog
    title="查看所有规格"
    :visible.sync="visible"
    :append-to-body="true"
    :before-close="cancel"
    width="600px"
  >
        <el-table
        ref="multipleTable"
        :data="data"
        style="width: 100%"
      >
        <el-table-column
          prop="skuName"
          label="商品规格"
        >
        </el-table-column>
        <el-table-column
          align="right"
          prop="quantity"
          label="件数"
        >
        </el-table-column>
      </el-table>
      <div class="total">
        合计数量：<span class="total-number">{{total}}件</span>
      </div>
  </el-dialog>
</template>

<script>
import * as R from 'ramda'

export default {
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    data: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {}
  },
  computed: {
    total () {
      if (!this.data || R.isEmpty(this.data)) {
        return 0
      }
      return this.data.reduce((a, c) => {
        a += c.quantity
        return a
      }, 0)
    }
  },
  methods: {
    cancel () {
      this.$emit('onCancel')
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  border-top: 1px solid #D9E0F0FF;
  padding-top: 30px;
  text-align: center;
}

.total {
  font-size: 18px;
  margin-top: 20px;
  &-number {
    color: #1D90FBFF;
  }
}

</style>
